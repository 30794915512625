/* stylelint-disable selector-max-specificity, selector-no-qualifying-type, selector-max-compound-selectors, selector-max-id */
.s-message {
    width: 90%;
    margin: 18px auto;
    &.alert {
        padding: 16px 35px 16px 14px
    }
}

.s-message img {
    float: right;
}

.span3-addon1 {
    width: 229px;
}

.fg-toolbar {
    /* stylelint-disable-next-line declaration-no-important */
    padding: 5px !important;
}

.userBox {
    float: right;
    display: block;
    margin-right: 16px;
    background: $gray-light;
    border-radius: 0 0 $border-radius $border-radius;
    color: $primary;
    padding: 6px 6px 8px 6px;
    box-shadow: $box-shadow;
    a {
        color: $primary;

        &:hover, &:active, &:focus {
            color: $primary-font-color;
        }
    }
}

.userBox-left {
}

.userBox-right {
    float: left;
}

.userBox-data {
    display: block;
    margin-top: 5px;
}

.f-error {
    color: #900;
    display: block;
    font-size: 11px;
}

.fleft {
    float: left;
}

.fright {
    float: right;
}

.modal h3 {
    margin: 0;
    padding: 0;
}

.dropdown li {
    padding: 0;
}

.dropdown a {
    text-decoration: none;
}

a.btn {
    text-decoration: none;
}

#divLogin {
    display: block;
}

#divForgot {
    display: none;
}

.loginBox {
    display: flex;
    margin: 0 auto;
    max-width: 400px;
}

/* ### text box login ### */
.loginBox .textBox {
    padding: 4px;
}

.loginBox .textBox p {
    padding-bottom: 18px;
    color: #3f3d3d;
    line-height: 18px;
}

.loginBox label {
    color: #039;
    display: block;
    font-weight: bold;
    padding: 5px 0;
}

.loginBox .bottomRow {
    justify-content: space-around;
}

.contentLeft {
    width: 65%;
    float: left;
    background-color: $gray-light;
    box-shadow: $box-shadow-full;
    padding: 24px;
    border-radius: $border-radius;
}

.contentRight {
    width: 25%;
    float: right;
}

.contentRight img {
    display: inline;
}

/* ### Form rows ### */
.formRow {
    display: block;
    line-height: 22px;
    margin-bottom: 5px;
}

.formRow::after {
    content: '.';
    display: block;
    clear: both;
    visibility: hidden;
    line-height: 0;
    height: 0;
}

.formRow label {
    color: $primary-font-color;
    font-family: $lato;
    display: block;
    float: left;
    font-weight: 700;
}

.formRow input {
    display: block;
    padding: 6px;
}

.formRow select {
    display: block;
    float: right;
    padding: 8px;
    height: auto;
}

.formRow input.hasDatepicker {
    color: $primary-font-color;
    display: block;
    float: right;
    width: 45%;
    padding: 6px;
}
.input-append .add-on, .input-prepend .add-on {
    padding: 8px;
}

.formRow a {
    float: left;
    margin-left: 5px;
}

.formRow .ui-datepicker-trigger {
    float: right;
    margin: 4px 10px 0 0;
}

.formRow .bottomRow {
    margin: 0 10px;
}

.formRow .f-error {
    width: 20px;
    float: left;
    margin-left: 5px;
    text-indent: -9999px;
    background: url('../images/icons/exclamation.png') no-repeat 0 0;
}

.table {
    background: $table-background;
    border-left: 1px solid $table-border-color;
}

.table th {
    padding: 8px 12px;
    background-color: #fff;
    color: $table-header;
    font-weight: 300;
}

.table-bordered tbody {
    border: 1px solid $table-border-color;
}

.table-bordered td, .table-bordered th {
    border-left: 0;
    border-right: 0;
}

.table th a {
    text-decoration: none;
    color: $primary-font-color;
}

.table th a.desc {
    padding-right: 20px;
    background: url('../images/icons/up.png') right no-repeat;
}

.table th .asc {
    padding-right: 20px;
    background: url('../images/icons/down.png') right no-repeat;
}

.table th i {
    display: inline;
}

.table tr.odd {
    background: #fff;
}

.table tr.even {
    background: #f1f2f3;
}

.table.hover tbody tr {
    transition: $transition;
    &:hover, &:focus, &:active {
        box-shadow: $box-shadow;
    }
}

.table td {
    padding: 12px;
}

.table tr.click {
    cursor: pointer;
}
/* OVERVIEW */
.overview-searchbar {
    display: block;
    height: 30px;
    margin-bottom: 10px;
}

.overview-searchbar .sb-search {
    display: block;
    width: 380px;
    height: 30px;
}

.overview-searchbar .sb-filters {
    display: block;
    height: 30px;
}

.pagination li {
    float: left;
    padding: 0;
    width: auto;
}

.pagination li a {
    color: #0f5ca2;
}
/* SETTINGS */
.settingsLeft h2,
.settingsRight h2 {
    font-size: 20px;
}

.settingsLeft {
    width: 42%;
    float: left;
    padding: 10px;
    margin-left: 40px;
}

.settingsRight {
    width: 42%;
    float: right;
    padding: 10px;
    margin-right: 40px;
}

.settingsRight input.textBox,
.settingsRight select {
    width: 300px;
    margin-left: 20px;
}

.settingsRight select {
    width: 313px;
}

.settingsLeft .type,
.settingsLeft .action {
    width: 50px;
}
/*Application pagina*/
.app-column-header {
    position: relative;
    margin-bottom: -18px;
}

.app-column-header h3 {
    float: left;
}

.app-column-header .fright {
    margin-top: 8px;
}

.app-column-header h4 {
    display: inline;
    float: right;
    margin-left: 5px;
    margin-top: 2px;
}

.app-content .app-attr {
    font-family: $lato;
    font-weight: 700;
    padding-left: 4%;
}

.app-content .app-buttons {
    margin-bottom: 0;
}

.app-content .app-buttons button {
    margin-right: 5px;
}

.doc-modal p {
    margin: 0;
    padding: 0;
}

.doc-modal p {
    margin: 10px;
}

.doc-modal ul {
    margin: 10px;
}

.doc-modal ul {
    list-style: circle;
    list-style-position: outside;
    overflow: visible;
}

.doc-modal ul li {
    padding-left: 8px;
    width: 90%;
    font-family: Verdana;
    color: #3f3d3d;
    float: none;
    margin-left: 20px;
}

.doc-reject-modal textarea {
    width: 98%;
}

#applications .info-tooltip {
    height: 16px;
    width: 16px;
}

#applications .info-row {
    width: 16px;
}
/* File modal */
.file-modal {
    border: 1px solid #b2c1e0;
}

.file-header {
    border-bottom: 1px solid #ccc;
    margin-bottom: 5px;
    background-color: #d5d5d5;
}

.file-modal .file {
    line-height: 30px;
}

/* CMS */
.navbar {
    margin: 20px;
}

.navbar .nav {
    padding-bottom: 0;
}

.navbar li {
    float: left;
    padding: 0;
    width: auto;
}

.sortable li {
    list-style: none;
    border: 1px solid #ccc;
    background: #f6f6f6;
    color: #1c94c4;
    margin: 5px;
    padding: 5px;
    height: 22px;
}
