/*
	Datepicker for Bootstrap
	Copyright 2012 Stefan Petre
	Licensed under the Apache License v2.0
	http://www.apache.org/licenses/LICENSE-2.0
*/
/* stylelint-disable selector-max-specificity, selector-no-qualifying-type, selector-max-compound-selectors */
.datepicker {
    top: 0;
    left: 0;
    padding: 4px;
    margin-top: 1px;
    border-radius: 4px;
}

.datepicker::before {
    content: '';
    display: inline-block;
    border-left: 7px solid transparent;
    border-right: 7px solid transparent;
    border-bottom: 7px solid #ccc;
    border-bottom-color: rgba(0, 0, 0, 0.2);
    position: absolute;
    top: -7px;
    left: 6px;
}

.datepicker::after {
    content: '';
    display: inline-block;
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    border-bottom: 6px solid #fff;
    position: absolute;
    top: -6px;
    left: 7px;
}

.datepicker > div {
    display: none;
}

.datepicker table {
    width: 100%;
    margin: 0;
}

.datepicker td,
.datepicker th {
    text-align: center;
    width: 20px;
    height: 20px;
    border-radius: 4px;
}

.datepicker td.day:hover {
    background: #eee;
    cursor: pointer;
}

.datepicker td.old,
.datepicker td.new {
    color: #999;
}

.datepicker td.active,
.datepicker td.active:hover {
    background-color: #006dcc;
    background-image: linear-gradient(top, #08c, #04c);
    background-repeat: repeat-x;
    border-color: #04c #04c #002a80;
    border-color: rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.25);
    color: #fff;
    text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
}

.datepicker td.active:hover,
.datepicker td.active:hover:hover,
.datepicker td.active:active,
.datepicker td.active:hover:active,
.datepicker td.active.active,
.datepicker td.active:hover.active,
.datepicker td.active.disabled,
.datepicker td.active:hover.disabled,
.datepicker td.active[disabled],
.datepicker td.active:hover[disabled] {
    background-color: #04c;
}

.datepicker td.active:active,
.datepicker td.active:hover:active,
.datepicker td.active.active,
.datepicker td.active:hover.active {
    background-color: #039 \9;
}

.datepicker td span {
    display: block;
    width: 47px;
    height: 54px;
    line-height: 54px;
    float: left;
    margin: 2px;
    cursor: pointer;
    border-radius: 4px;
}

.datepicker td span:hover {
    background: #eee;
}

.datepicker td span.active {
    background-color: #006dcc;
    background-image: linear-gradient(top, #08c, #04c);
    background-repeat: repeat-x;
    border-color: #04c #04c #002a80;
    border-color: rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.25);
    color: #fff;
    text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
}

.datepicker td span.active:hover,
.datepicker td span.active:active,
.datepicker td span.active.active,
.datepicker td span.active.disabled,
.datepicker td span.active[disabled] {
    background-color: #04c;
}

.datepicker td span.active:active,
.datepicker td span.active.active {
    background-color: #039 \9;
}

.datepicker td span.old {
    color: #999;
}

.datepicker th.switch {
    width: 145px;
}

.datepicker th.next,
.datepicker th.prev {
    font-size: 19.5px;
}

.datepicker thead tr:first-child th {
    cursor: pointer;
}

.datepicker thead tr:first-child th:hover {
    background: #eee;
}

.input-append.date .add-on i,
.input-prepend.date .add-on i {
    display: block;
    cursor: pointer;
    width: 16px;
    height: 16px;
}
