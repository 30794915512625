/**
 * Fonts
 */
@import url('https://fonts.googleapis.com/css?family=Lato:300,400,700&display=swap');
@import url('https://fonts.googleapis.com/css?family=Roboto:300,400&display=swap');

/*
 * # Vendor
 * External packages
 */
@import 'summernote/dist/summernote';

/**
 * # Variables
 */

@import 'variables';

/*
 * # Legacy
 */
@import 'legacy/bootstrap';
@import 'legacy/indcloud';
@import 'legacy/global';
@import 'legacy/jquery-ui-1.9.1.custom';
@import 'legacy/datepicker';
@import 'legacy/jquery.dataTables';
@import 'components/log';

/*
 * # Utilities
 * Very specific helper styling that are easy to use and have a simple purpose
 * like clearfix or hacks
 */
@import 'utilities/hacks-summernote';

td .btn {
    .btn-small {
        max-width: 50px;
        text-align: center;
        padding: 5px;
    }
}

.pb-0.pb-0 {
    padding-bottom: 0;
}
