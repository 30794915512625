// colors
$primary: #037ff3;
$primary-font-color: #0b333c;
$secondary-font-color: #fafafa;
$white: #fff;
$black: #000;
$gray: #eee;
$gray-light: #fafafa;
$gray-darker: #ddd;
$gray-darkest: darken($gray-darker, 30%);
$red: #d8000c;

// fonts
$lato: 'Lato', Arial, sans-serif;
$roboto: 'Roboto', Calibri, sans-serif;
$font-family-body: $roboto;
$font-family-heading: $lato;

// container
$container-background: $white;
$container-width: 1200px;

// radius
$border-radius: 6px;

// box shadow
$box-shadow: 0 0.15rem 0.3rem rgba(0, 0, 0, 0.25);
$box-shadow-full: 0 0.2rem 0.4rem rgba(0, 0, 0, 0.25);

// menu
$header-background: $primary;
$menu-background: $primary;
$menu-gradient: linear-gradient(180deg, #037ff3, #223741 64%) fixed no-repeat;
$menu-height: 32px;
$menu-font: $lato;
$menu-font-color: $gray-light;
$menu-button-border-bottom-hover: $menu-font-color;
$menu-offset-top: 118px;

// transition
$transition: 0.1s ease-in;

// table
$table-background: $gray-light;
$table-header: $primary-font-color;
$table-border-color: #eee;

// buttons
$button-default-border-color: #cacaca;
