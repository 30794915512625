.table-log {
    th:first-child,
    td:first-child {
        width: 80%;
    }
}

.comment-form {
    display: flex;
    align-items: flex-start;
}

.comment-form-input {
    width: 100%;
    height: 66px;
    box-sizing: border-box;
    margin-bottom: 0;
    margin-right: 12px;
}
