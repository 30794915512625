// Prevent summernote from popping open all modals on load.
// https://stackoverflow.com/questions/38376856/how-to-prevent-summernote-from-popping-open-all-modals
.note-editor .modal {
    display: none;
}

// Summernote uses a btn group but places a tooltip element between the buttons, messing up the sibling selector.
// stylelint-disable-next-line
.note-editor .btn-group > .btn + .tooltip + .btn {
    margin-left: -1px;
}

// Reset some styles inherited from .contentBox so the ul/ol look good.
.note-editor ul,
.note-editor ol {
    overflow: initial;
    margin: initial;
    margin-left: 1em;

    li {
        padding: initial;
        margin: initial;
        float: initial;
    }
}

.note-editor ul li {
    list-style-type: disc;
}
